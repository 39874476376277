import ContactUsCss from './contact.module.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

import { Col, Container, Row,Button } from 'react-bootstrap';
import ContactUsBanner from '../img/contactUs/contactUsBanner.webp';
import ContactUsFormImg from '../img/contactUs/form.webp';
import { Helmet } from 'react-helmet';
import {useState} from 'react'
import tick from '../../components/img/rightTick.png'


function ContactUs() {
  const [values, setValues] = useState({
    "fullName":"",
    "address":"",
    "contactNo":"",
    "orgType":"",
    "userType":null,
    "query":""
  })
  const [showPopup, setShowPopup] = useState({status:false, message:""})
  function handleChanges(e, type){
    if(type=="num" ){
      if(e.target.value.length>10){
        alert('Invalid contact number')
      }else{
       setValues({...values, [e.target.name]:e.target.value})
      }
    }else{
      setValues({...values, [e.target.name]:e.target.value})
    }
  }


  function submit(){
    const payload = JSON.stringify(values)
    console.log(payload, 'check value')
    fetch('https://swaadhyayan.com/swaBlog/api/contact/contactDetails.php',{
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: payload
     })
     .then((res)=>{
        return res.json();
     })
     .then((data)=>{
      if(data.status=="success")
        setShowPopup((prev)=>{
      return{...prev, status:true, message:data.message}})
     })
  }
  function resetForm(){
    setValues({
      fullName:"",
      address:"",
      contactNo:"",
      orgType:"",
      userType:"",
      query:""
    })
  }

  function closePopup(){
    setShowPopup((prev)=>{
      return{...prev, status:false}})
    setValues({
      fullName:"",
      address:"",
      contactNo:"",
      orgType:"",
      userType:"",
      query:""
    })
  }
  

  return (
    <>
    <div className={ContactUsCss.mainContactBg}>
      <Helmet>
        <title>Contact us for Best School Digital Learning Platform - Swa Adhyayan</title>
        <meta name="description" content="The Online Learning Platform offers interesting and stimulating module to identify the preferred learning style of child and build their progressive proficiency."/>
        <meta property="og:title" content="Contact us for Best School Digital Learning Platform - Swa Adhyayan"/> 
        <meta property="og:description" content="The Online Learning Platform offers interesting and stimulating module to identify the preferred learning style of child and build their progressive proficiency"/> 
        <meta property="og:url" content="https://swaadhyayan.com/ContactUs"/> 
        <meta property="og:site_name" content="Swa Adhyayan"/> 
        <meta property="og:image" content="https://swaadhyayan.com/static/media/contactUsBanner.e35e7253.webp"/> 
        <meta name="twitter:site" content="@swaadhyayan"/> 
        <meta name="twitter:title" content="Contact us for Best School Digital Learning Platform - Swa Adhyayan"/>
        <meta name="twitter:description" content="The Online Learning Platform offers interesting and stimulating module to identify the preferred learning style of child and build their progressive proficiency"/>
        <meta name="twitter:image" content="https://swaadhyayan.com/static/media/contactUsBanner.e35e7253.webp"/>
      </Helmet>

      {/* Banner Section */}
      <div className={ContactUsCss.Banner}>
        <img className={ContactUsCss.banner} src={ContactUsBanner}/>
      </div>
      {/* Banner Section */}

      {/* Second Section */}
      <div className={ContactUsCss.SecontSection}>
        <Container>
          <Row className="justify-content-around">
            <Col className="col-md-4 col-sm-6 col-12">
                <div class={ContactUsCss.serviceBox}>
                    <div class={ContactUsCss.service_icon}>
                        <LocationOnIcon className={ContactUsCss.iconInbox}/>
                    </div>
                    <div class={ContactUsCss.service_content}>
                        <h3 class={ContactUsCss.title}> ADDRESS </h3>
                        <p class={ContactUsCss.description}>
                        4583/15, Opp. LIC Building,<br/>  Daryaganj, P.O. Box 7226, New Delhi-110002 
                        </p>
                    </div>
                </div>
            </Col>
            <Col className="col-md-4 col-sm-6 col-12">
                <div class={ContactUsCss.serviceBox}>
                    <div class={ContactUsCss.service_icon}>
                        <LocalPhoneIcon className={ContactUsCss.iconInbox}/>
                    </div>
                    <div class={ContactUsCss.service_content}>
                        <h3 class={ContactUsCss.title}>PHONE NUMBER </h3>
                        <p class={ContactUsCss.description}>
                        011 - 43585858,  23285568<br />
                        011 - 23243519
                        </p>
                    </div>
                </div>
            </Col>
            <Col className="col-md-4 col-sm-6 col-12">
                <div class={ContactUsCss.serviceBox}>
                    <div class={ContactUsCss.service_icon}>
                        <EmailIcon className={ContactUsCss.iconInbox}/>
                    </div>
                    <div class={ContactUsCss.service_content}>
                        <h3 class={ContactUsCss.title}> EMAIL ADDRESS </h3>
                        <p class={ContactUsCss.description}>
                        <a href="mailto:info@swaadhyayan.com">info@swaadhyayan.com</a>
                        </p>
                    </div>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/*Second Section*/}
      {/*Form Section*/}
      <div className={ContactUsCss.formBg}>
      <Container>
      <div className={ContactUsCss.sendUs}>SEND US A MESSAGE </div>
        <Row>
          <Col className={`col-md-6 col-sm-12 col-12 ${ContactUsCss.picContainer}`}>
             <img className={ContactUsCss.formIMGs} src={ContactUsFormImg}/>
          </Col>
          <Col className="col-md-6 col-sm-12 col-12">
            <div className={ContactUsCss.formInputbg}>
              <label>Full Name :</label>
              <input type="text" name="fullName" required placeholder="" onChange = {(e)=> handleChanges(e)} value={values.fullName}/>
              <label>Email address :</label>
              <input type="text" name="address" required placeholder="" onChange = {(e)=> handleChanges(e)} value={values.address}/>
              <label>Mobile number :</label>
              <input type="number" name="contactNo" maxlength={10} required placeholder="" onChange = {(e)=> handleChanges(e, 'num')} value={values.contactNo}/>
              <label>Organisation Type:</label>
              <select className={ContactUsCss.dropStyle} required name="orgType" id="orgType" onChange = {(e)=> handleChanges(e)} value={values.orgType}>
                  <option value="select" style={{display:"none"}}>Select</option>
                  <option value="School">School</option>
                  <option value="Institute">Institute</option>
                </select>
                <label>User Type :</label>
              <select className={ContactUsCss.dropStyle} required name="userType" id="userType" onChange = {(e)=> handleChanges(e)} value={values.userType}>
                  <option value="select" style={{display:"none"}}>Select</option>
                  <option value="1" >School Admin</option>
                  <option value="2" >Teacher</option>
                  <option value="3" >Student</option>
                  <option value="4" >Parent</option>
                </select>
                <label>Query :</label>
              <textarea required name="query" onChange = {(e)=> handleChanges(e)} value={values.query}></textarea>
              
            <Row style={{justifyContent:'space-around', display:'flex'}}>
              <Col className="col-auto text-center">
                {/* <div className={ContactUsCss.Reset} onClick={()=>alert('hello reset')}>Reset</div> */}
                <button className={`btn btn-primary btn-xs ${ContactUsCss.Reset}`} name="reset" onClick={()=>resetForm()}>Reset</button>
              </Col>
              <Col className="col-auto text-center"> 
                {/* <div className={ContactUsCss.Submit}>Submit</div>*/}
                <button className={`btn btn-success btn-xs ${ContactUsCss.Submit}`} onClick={()=>submit()} name="submit" >Submit</button>
              </Col>
            </Row>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
      {/* Form Section */}
   </div>
     {showPopup.status &&
      <div className='popupD'>
      <div className='imgPopup'>
          <div style={{width:"350px", height:"300px", padding:"10px", backgroundColor:'#ffff', borderRadius:"10px", justifyContent:'center', alignItems:'center'}}>
            <div className={ContactUsCss.circle}>
            <img src={tick}  style={{width:"50%"}} alt=""/>

            </div>
              <div style={{textAlign:'center', fontSize:28, fontStyle:'bold', color:'#000', marginTop:"15px"}}> Thank You!</div>
              <div style={{textAlign:'center'}}> {showPopup.message}</div>
              <div style={{textAlign:'center', margin:15}}>
              <button className={`btn btn-success btn-xs `} style={{width:"150px"}} onClick={closePopup}>OK</button>
          </div>
          </div>
      </div>
      </div>
     }
    </>
  );
}

export default ContactUs;
