import React, { useEffect, useRef, useState } from 'react'
import { Container,} from 'react-bootstrap';
import BlogCss from './Blog.module.css'
import loaderImg from '../img/blog/loading-chain.gif'

// import data from './data';



import { Helmet } from 'react-helmet';

import { Link} from "react-router-dom";
import Pagination from './BlogPagination'


const Blog = () => {

  
  const [query, setQuery] = useState("")
  const [showPop, setShowPop] = useState(false);
  const [loading, setLoading] = useState(false)
  const [selectData, setSelectData] = useState()
  const [showPerPage, setShowPerPage] = useState(4)
  const [pagination, setPagination] =useState({
    start: 0,
    end: showPerPage

  });
    
    const [blogdata, setBloagData] = useState([])

    useEffect(() => {
      setLoading(true)
      fetch('https://swaadhyayan.com/swaBlog/api/posts/posts.php')
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setLoading(false)
          var newArray = [];
          Object.values(data).map((item, i) => (
            newArray.push(item)
          ))
          setBloagData(newArray.reverse())

        });
    }, []);


// useEffect(()=>{
//   setLoading(true)
//   const payload = {
//     method:"POST",
//     headers:{'Content-Type': 'application/json'},
//     body:JSON.stringify({"token":"SWA@_SAHRING#_API*_712"})
//   }
//   fetch('https://swaadhyayan.com/school/appAPI/AppAPIController/getAllBlogPost', payload)
//   .then((res)=>res.json())
//   .then(data=>{
//     console.log(JSON.stringify(data),'blogs data')
//     if(data.status=="success"){
//       setBloagData(JSON.parse(JSON.stringify(data.data)))
//       setLoading(false)
//     }else{
//       alert('data not found')
//     }
//   })
//   .catch((err)=>{
//     console.log(err)
//   })
// },[]) 

  const onPaginationChange = (start, end)=>{
    setPagination((prev)=>{
      return{...prev, start:start, end:end}
    })
    
  }

  function showPopup(item) {
    setShowPop(true)
    setSelectData(item)

    return(
      <div>{item.url}</div>
    )
  }
  function goback() {
    setShowPop(false)
    setQuery("")
  }


  return (
    <div className={BlogCss.background}>

      <Helmet>
        <title>Swa-Adhyayan Blog | Best LMS Software in India | Blogs</title>
        <meta name="description" content="- Swa-Adhyayan is the unique Learning, Assessment and Sharing Platform based on Multiple Intelligences that includes both learning & assessment processes." />
        <meta property="og:title" content="Swa-Adhyayan Blog | Best LMS Software in India | Blogs" /> 
        <meta property="og:description" content="Swa-Adhyayan is the unique Learning, Assessment and Sharing Platform based on Multiple Intelligences that includes both learning & assessment processes"/> 
        <meta property="og:url" content="https://swaadhyayan.com/Blog" /> 
        <meta property="og:site_name" content="Swa Adhyayan" /> 
        <meta property="og:image" content="https://swaadhyayan.com/static/media/img0.e5611c6f.jpg" /> 
        <meta name="twitter:site" content="@swaadhyayan"/> 
        <meta name="twitter:title" content="Swa-Adhyayan Blog | Best LMS Software in India | Blogs"/>
        <meta name="twitter:description" content="Swa-Adhyayan is the unique Learning, Assessment and Sharing Platform based on Multiple Intelligences that includes both learning & assessment processes" />
        <meta name="twitter:image" content="https://swaadhyayan.com/static/media/img0.e5611c6f.jpg" />
      </Helmet>

      <Container className='py-5'>
      {loading==true?
          <div className='loaderA'>
            <img src={loaderImg} alt="" style={{width:200, height:200}}/>
          </div>
          :
        <div className={BlogCss.containerBg}>
          <div>
           <div className={BlogCss.blogs}>
              {blogdata.filter((item) => item.blogHeading.toLocaleLowerCase().includes(query)).slice(pagination.start, pagination.end).map((item, index) => {

                let blogBasePath = "https://swaadhyayan.com/assets/blogImages/"       
                
                
                let newMsg = ''
                if (item.blogDescription.length > 90) {
                  newMsg = item.blogDescription.substring(0, 90) + '...'
                }else{
                  newMsg = item.blogDescription
                }

                let newHead = ''
                if(item.blogHeading.length > 50){
                  newHead = item.blogHeading.substring(0,50) + '...'
                }else{
                  newHead = item.blogHeading
                }
                return (
                  <div className={BlogCss.blogContainer} key={item.id}>
                    <Link to={`/blog/${item.blogUrl}`}  state={{ data: item }}>
                      <div className={BlogCss.blogImgContainer}>
                        <img className='img-fluid' src={blogBasePath+item.blogImageName} alt="swaadhyayan" />
                      </div>
                    </Link>

                    <div className={BlogCss.textBox}>
                    <Link to={`/blog/${item.blogUrl}`} state={{ data: item }} style={{textDecoration:'none'}}><div className={BlogCss.blogHeading}>{newHead}</div></Link>
                      <div className={BlogCss.blogLabel}>{item.blogPublishDate}, By {item.blogPostedBy}</div>
                      <div className={BlogCss.blogText}
                      dangerouslySetInnerHTML={{__html: newMsg}}
                      />
                    </div>

                    <div className={BlogCss.blogReadMore}>
                    <Link to={`/blog/${item.blogUrl}`} state={{ data: item }} style={{textDecoration:'none'}}><button className='btn btn-primary'>Read more...</button></Link>
                    </div>
                  </div>
                )
              })}
            </div> 
            {blogdata.length?
            <Pagination showPerPage={showPerPage} onPaginationChange={onPaginationChange} total={blogdata.length}/>:null
            }
          </div>
          <div className={BlogCss.searchBox}>
            <nav className="">
              <form className="form-inline">
                <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" onChange={e => setQuery(e.target.value)} />
              </form>
            </nav>
            <div className={BlogCss.recentPostContainer}>
              <div className={BlogCss.recentPostHead}>Recent Posts</div>
              <div className={BlogCss.postList}>
                <ul>
                  {blogdata.slice(0,10).map((item, index) => {
                    return (
                      // <li className={BlogCss.listHeading} onClick={() => showPopup(item)} >{item.heading}</li>
                      <Link to={`/blog/${item.blogUrl}`} state={{ data: item }} style={{textDecoration:'none'}}><li className={BlogCss.listHeading}>{item.blogHeading}</li></Link>
                    )
                  })}

        </ul>
              </div>
            </div>

          </div>

        </div>
      }
      </Container>
    </div>
  )
}

export default Blog